<template>
  <vs-card>
    <h3 class="mb-4">
      {{ prizeName }}
    </h3>

    <div class="d-flex flex-column flex-nowrap align-items-start">
      <form>
        <!-- General -->
        <vs-row>
          <vs-col
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-row>
              <vs-col
                vs-lg="3"
                vs-sm="12"
                vs-xs="12"
              >
                <vs-input
                  v-model="prizeDate"
                  v-mask="'##/##/####'"
                  class="w-100"
                  icon="event"
                  label="Data do Sorteio*"
                  placeholder="DD/MM/YYYY"
                />
              </vs-col>

              <vs-col
                vs-lg="3"
                vs-sm="12"
                vs-xs="12"
              >
                <vs-input
                  v-model="prizeTime"
                  v-mask="'##:##'"
                  class="w-100"
                  icon="schedule"
                  label="Horário do Sorteio*"
                  placeholder="00:00"
                />
              </vs-col>

              <vs-col
                vs-lg="3"
                vs-sm="12"
                vs-xs="12"
                class="d-flex flex-column"
              >
                <label class="vs-input--label">
                  Número de ganhadores*
                </label>

                <div class="d-flex align-items-center mr-5">
                  <vs-button
                    icon="remove"
                    radius
                    size="small"
                    @click="handleQtyRemove"
                  />

                  <vs-input
                    v-model.number="prizeQty"
                    class="c-draw-number-input"
                    type="number"
                  />

                  <vs-button
                    icon="add"
                    class="mr-5"
                    radius
                    size="small"
                    @click="handleQtyAdd"
                  />
                </div>
              </vs-col>

              <vs-col
                vs-lg="3"
                vs-sm="12"
                vs-xs="12"
                class="d-flex align-items-center"
              >
                <PrizeDrawBlacklist
                  :restriction-limit-date="restrictionLimitDate"
                  :restriction-type="restrictionType"
                  @set-restriction-limit-date="(val) => (restrictionLimitDate = val)"
                  @set-restriction-type="(val) => (restrictionType = val)"
                />
              </vs-col>
            </vs-row>

            <vs-row class="mt-3">
              <vs-col>
                <small>
                  Obs.: A ação de sorteio só será liberada a partir da data e horário informados.
                </small>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>

        <vs-divider class="mb-4" />

        <!-- Registration -->
        <vs-card>
          <div
            slot="header"
            class="d-flex align-items-center"
          >
            <vs-checkbox
              v-model="signToParticipate"
              :color="colorSecondary"
            >
              <h5>Inscrições</h5>
            </vs-checkbox>
          </div>

          <vs-row>
            <!-- General -->
            <vs-col
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >
              <vs-row>
                <!-- Date/Time Start -->
                <vs-col
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="mb-4"
                >
                  <vs-row>
                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignInitDate"
                        v-mask="'##/##/####'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="today"
                        label="Data de Início"
                        placeholder="DD/MM/YYYY"
                      />
                    </vs-col>

                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignInitTime"
                        v-mask="'##:##'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="schedule"
                        label="Horário de Início"
                        placeholder="00:00"
                      />
                    </vs-col>
                  </vs-row>
                </vs-col>

                <!-- Date/Time End -->
                <vs-col
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <vs-row>
                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignEndDate"
                        v-mask="'##/##/####'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="event"
                        label="Data Final"
                        placeholder="DD/MM/YYYY"
                      />
                    </vs-col>

                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignEndTime"
                        v-mask="'##:##'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="schedule"
                        label="Horário Final"
                        placeholder="00:00"
                      />
                    </vs-col>
                  </vs-row>
                </vs-col>
              </vs-row>
            </vs-col>

            <vs-col
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              :style="{ paddingLeft: '2rem' }"
            >
              <h6 class="mb-3">
                Pesquisa de Inscrição
              </h6>

              <!-- Survey Params -->
              <PrizeSurveyParamsForm
                :params="surveyParams"
                :sign-to-participate="signToParticipate"
                @set-params="(val) => (params = val)"
              />
            </vs-col>
          </vs-row>
        </vs-card>
      </form>

      <small class="mt-2 mb-3">
        * Indica campos obrigatórios
      </small>

      <vs-button
        :disabled="!validForm"
        size="large"
        @click="updateDraw"
      >
        Salvar Sorteio
      </vs-button>
    </div>
  </vs-card>
</template>

<script>
/* Components */
import PrizeDrawBlacklist from '../components/PrizeDrawBlacklist.vue';
import PrizeSurveyParamsForm from '../components/PrizeSurveyParamsForm.vue';

/* Directives */
import { mask } from 'vue-the-mask';

/* Services */
import Prize from '@/services/prize';

const prizeService = new Prize();

/* Validations */
import validatePrizeDraw from '../helpers/validate-prize-draw';

/* Style */
import '../assets/index.scss';

export default {
  name: 'PrizeDrawView',
  components: {
    PrizeDrawBlacklist,
    PrizeSurveyParamsForm,
  },
  directives: { mask },
  props: {
    selectedDraw: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    /* General */
    drawCode: '',
    prizeDate: '',
    prizeName: '',
    prizeTime: '',
    prizeQty: 0,
    /* Blacklist */
    restrictionLimitDate: '',
    restrictionType: 1,
    /* Survey */
    surveyParams: {},
    prizeSignEndDate: '',
    prizeSignEndTime: '',
    prizeSignInitDate: '',
    prizeSignInitTime: '',
    signToParticipate: false,
  }),
  computed: {
    /* Store */
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    /* Submit Form */
    drawPayload() {
      let payload = {
        drawCode: this.drawCode,
        prizeDate: this.prizeDate,
        prizeTime: this.prizeTime,
        signToParticipate: this.signToParticipate,
        prizeSignInitDate: this.prizeSignInitDate,
        prizeSignInitTime: this.prizeSignInitTime,
        prizeSignEndDate: this.prizeSignEndDate,
        prizeSignEndTime: this.prizeSignEndTime,
        prizeQty: this.prizeQty,
        surveyParams: this.surveyParams,
        restrictionType: this.restrictionType,
        restrictionLimitDate: this.restrictionLimitDate,
      };

      return payload;
    },
    validForm() {
      return validatePrizeDraw(this.$data);
    },
  },
  created() {
    this.setForm(this.selectedDraw);
  },
  methods: {
    /* API */
    updateDraw() {
      prizeService.updatePrizeDraw(
        this.drawPayload,
        this.token,
      )
        .then(() => {
          this.successAlert();
          this.goToDraws();
        })
        .catch((err) => this.errorAlert(err));
    },
    /* Helpers */
    handleQtyAdd() {
      const max = this.filterIdCount >= 1 ?
        this.filterIdCount :
        500;

      if (this.prizeQty < max) this.prizeQty += 1;
    },
    handleQtyRemove() {
      if (this.prizeQty > 1) this.prizeQty -= 1;
    },
    setForm(obj) {
      if ('drawMethod' in obj) {
        this.signToParticipate = obj.drawMethod === 1;
      }

      this.drawCode = obj.drawCode;
      this.prizeName = obj.prizeName;
      this.prizeDate = obj.prizeDate;
      this.prizeTime = obj.prizeTime;
      this.prizeQty = obj.prizeQty;
      this.prizeSignInitDate = obj.prizeSignInitDate || '';
      this.prizeSignInitTime = obj.prizeSignInitTime || '';
      this.prizeSignEndDate = obj.prizeSignEndDate || '';
      this.prizeSignEndTime = obj.prizeSignEndTime || '';
      this.surveyParams = obj.surveyParams || {};
      this.restrictionType = obj.restrictionType;
      this.restrictionLimitDate = obj.restrictionLimitDate;
    },
    /* Alerts */
    errorAlert(err) {
      console.error(err);

      this.$vs.notify({
        color: 'danger',
        title: 'Erro',
        text: err,
      });
    },
    successAlert() {
      this.$vs.notify({
        color: 'success',
        title: 'Sucesso',
        text: 'Sorteio salvo com sucesso!',
      });
    },
    /* Router */
    goToDraws() {
      this.$router.push('/sorteios/listar');
    },
  },
};
</script>

<style lang="scss">
/* Custom Draw Components */
.c-draw-link {
  color: black !important;
  font-size: 0.95em;
  text-decoration: underline !important;
}

.c-draw-link:hover {
  color: inherit !important;
  cursor: pointer !important;
}

.c-draw-number-input {
  margin: 0 7px;

  .vs-input--input {
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.c-draw-vs-chip {
  display: flex;
  justify-content: stretch;

  .text-chip.vs-chip--text {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    width: 100%;
  }
}
</style>
